import { RxCross2 } from 'react-icons/rx';
import { useState } from 'react';

import React from 'react';
import './Filter.css';
import { useData } from '../../../../contexts/DataProvider';
import { words } from '../../../../constant/constants';

export const Filter = () => {
	const { dispatch, state } = useData();
	const [isFilterMenuOn, setIsFilterMenuOn] = useState(false);

	return (
		<div>
			<div
				className={
					isFilterMenuOn
						? 'filter-container filter-container-mobile-open'
						: 'filter-container filter-container-mobile-closed'
				}
			>
				<div
					className={
						!isFilterMenuOn
							? 'filter-header filter-header-mobile-closed'
							: 'filter-header filter-header-mobile-open'
					}
				>
					<span
						className='close-tab'
						onClick={() => setIsFilterMenuOn(!isFilterMenuOn)}
					>
						{!isFilterMenuOn ? (
							<></> // 	<TbAdjustmentsHorizontal />
						) : (
							<RxCross2 />
						)}
					</span>
					{/* <h2>Filters</h2> */}
				</div>

				<div
					className={
						isFilterMenuOn
							? 'filter-types-container filter-types-container-mobile'
							: 'filter-types-container'
					}
				>
					<div className='category-container'>
						<h3>{words.filterGenres}</h3>
						{/* <div className='category-input-container'> */}
						{state.allGenres?.map(({ genre }) => (
							<div
								className='category-input-container '
								key={genre}
							>
								<label htmlFor={`category-${genre}`}>
									<input
										checked={state.filters.genres.includes(
											genre
										)}
										onChange={() =>
											dispatch({
												type: 'ADD_GENRES',
												payload: genre,
											})
										}
										id={`category-${genre}`}
										type='checkbox'
									/>
									<span>{`${genre}`}</span>
								</label>
							</div>
						))}
						{/* </div> */}
					</div>
				</div>
			</div>
		</div>
	);
};
