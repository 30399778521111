import { useData } from '../../../../contexts/DataProvider';
import { words } from '../../../../constant/constants';
import { GenreTitle } from './GenreTitle';
import { BookList } from './BookList';
import { Fragment, useMemo } from 'react';
import { Fade } from 'react-awesome-reveal';
const HomeCarousles = () => {
	const { state } = useData();

	const newBooksList = state.allBooksFromApi
		.filter((a) => a.is_stock)
		.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
		.slice(0, 10);
	const newBooksHeading = words.newAddBooks;

	const GenreBooksList = useMemo(
		() =>
			state.allGenres.map((g) => ({
				title: g.genre,
				books: state.allBooksFromApi.filter(
					(b) => b.genre === g.genre && b.is_stock
				),
			})),
		[state]
	);
	console.log(GenreBooksList);

	const genreCarousel = GenreBooksList.filter((g) => g.books.length > 3).map(
		(g, i) => (
			<Fragment key={g.title}>
				<Fade cascade damping={0.3}>
					<GenreTitle title={g.title} />
					<BookList books={g.books} />
				</Fade>
			</Fragment>
		)
	);

	return (
		<>
			<Fragment key={'new'}>
				<Fade>
					<GenreTitle title={newBooksHeading} />
					<BookList books={newBooksList} />
				</Fade>
			</Fragment>
			{genreCarousel}
		</>
	);
};
export default HomeCarousles;
