import './HeroVideo.css';
import { useNavigate } from 'react-router-dom';

import React from 'react';
import { paths, words } from '../../../../constant/constants';

export const HeroVideo = () => {
	const Navigate = useNavigate();
	return (
		<div className='hero-video-container'>
			<img
				className='hero-video'
				src='/assets/images/book.jpg'
				alt='Kitaab'
			/>
			{/* </div> */}

			<div className='hero-text'>
				{/* <h1>{words.appName}</h1> */}
				<img
					className='hero-image'
					src='/assets/images/logo-white.png'
					alt='DAR'
				/>
				{/* <h2>Where Adventure Meets Style in Quirky Sneaker Bliss</h2> */}
				<h3 className='first-text'>
					بوابتك لرؤية إبداع وجمال المعرفة القديمة
				</h3>
				<h3 className='second-text'>
					بأندر الكتب والتحف الفنية الرائعة
				</h3>
			</div>

			<button
				onClick={() => Navigate(paths.booksListPath)}
				className='shop-now-btn main_btn_bk'
			>
				{words.shopNowButton}
			</button>
		</div>
	);
};
