import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthProvider';
import { paths } from '../../constant/constants';

export const RequiresAuth = ({ children }) => {
	const { auth } = useAuth();

	const location = useLocation();
	return auth.isAuth ? (
		children
	) : (
		<Navigate to={paths.loginPath} state={{ from: location }} />
	);
};
