import React from 'react';
import './carouselStyle.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CgArrowLeftO, CgArrowRightO } from 'react-icons/cg';

const Carousel = ({ children }) => {
	const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
		<button
			{...props}
			className={
				'slick-prev slick-arrow' +
				(currentSlide === 0 ? ' slick-disabled' : '')
			}
			aria-hidden='true'
			aria-disabled={currentSlide === 0 ? true : false}
			type='button'
		>
			<CgArrowLeftO
				{...props}
				style={{
					color: 'black',
				}}
			/>
		</button>
	);
	const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
		<button
			{...props}
			className={
				'slick-next slick-arrow' +
				(currentSlide === slideCount - 1 ? ' slick-disabled' : '')
			}
			aria-hidden='true'
			aria-disabled={currentSlide === slideCount - 1 ? true : false}
			type='button'
		>
			<CgArrowRightO
				{...props}
				style={{
					color: 'black',
				}}
			/>
		</button>
	);

	const settings = {
		//className: 'center',
		// centerMode: true,
		infinite: true,
		// centerPadding: '30px',
		slidesToShow: children.length > 4 ? 4 : children.length,
		slidesToScroll: 1,
		speed: 5000,
		prevArrow: <SlickArrowLeft />,
		nextArrow: <SlickArrowRight />,
		// dots: true,
		autoplay: true,
		autoplaySpeed: 3000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: children.length > 4 ? 4 : children.length,
					slidesToScroll: 1,
					initialSlide: 2,
					prevArrow: null,
					nextArrow: null,
					dots: false,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: children.length > 3 ? 3 : children.length,
					slidesToScroll: 1,
					initialSlide: 2,
					prevArrow: null,
					nextArrow: null,
					dots: false,
				},
			},
			{
				breakpoint: 480,
				settings: {
					centerMode: true,
					slidesToShow: children.length > 2 ? 2 : children.length,
					slidesToScroll: 1,
					prevArrow: null,
					nextArrow: null,
					dots: false,
				},
			},
		],
	};
	return (
		<div className='Carousel'>
			{/* <div> */}
			<Slider {...settings}>
				{children} {/* {this.getCategoryBook()} */}
			</Slider>
		</div>
	);
};

export default Carousel;
