import axios from 'axios';
import { backAPI } from '../constant/constants';

const userGetAddress = backAPI.userGetAddress;
const userAddAddress = backAPI.userAddAddress;
const userUpdateAddress = backAPI.userUpdateAddress;
const userRemoveAddress = backAPI.userRemoveAddress;

const auth_header = () => ({
	authorization: 'Bearer ' + window.localStorage.getItem('token'),
});
const getAddressList = async () => {
	return await axios.get(userGetAddress, {
		headers: auth_header(),
	});
};

const addAddress = async (address) => {
	return await axios.post(
		userAddAddress,
		{ ...address },
		{ headers: auth_header() }
	);
};

const updateAddress = async (address) => {
	return await axios.post(
		userUpdateAddress,
		{ ...address },
		{ headers: auth_header() }
	);
};

const removeAddress = async (addressId) => {
	//return await axios.delete(URL, {
	return await axios.post(
		userRemoveAddress,
		{ addressId },
		{ headers: auth_header() }
	);
};

const addressServices = {
	getAddressList,
	addAddress,
	updateAddress,
	removeAddress,
};

export default addressServices;
