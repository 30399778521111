import './ProductListingSection.css';
import Tilt from 'react-parallax-tilt';
import React from 'react';

import { useData } from '../../../../contexts/DataProvider';
import { Link, useNavigate } from 'react-router-dom';
import { getCategoryWiseProducts } from '../../../../helpers/filter-functions/category';
import { getRatedProducts } from '../../../../helpers/filter-functions/ratings';
import { getPricedProducts } from '../../../../helpers/filter-functions/price';
import { getSortedProducts } from '../../../../helpers/filter-functions/sort';
import { getSearchedProducts } from '../../../../helpers/searchedProducts';
//import { AiOutlineHeart } from 'react-icons/ai';
//import { AiTwotoneHeart } from 'react-icons/ai';
import { useUserData } from '../../../../contexts/UserDataProvider';

//import { BsFillStarFill } from 'react-icons/bs';
import { paths, words } from '../../../../constant/constants';

export const ProductListingSection = () => {
	const navigate = useNavigate();
	const { state } = useData();

	const { isProductInCart, addToCartHandler, cartLoading } = useUserData();

	const {
		allBooksFromApi,
		inputSearch,
		filters: { rating, genres, price, sort },
	} = state;

	const searchedProducts = getSearchedProducts(allBooksFromApi, inputSearch);

	const ratedProducts = getRatedProducts(searchedProducts, rating);

	const categoryProducts = getCategoryWiseProducts(ratedProducts, genres);

	const pricedProducts = getPricedProducts(categoryProducts, price);

	const sortedProducts = getSortedProducts(pricedProducts, sort);
	return (
		<div className='product-card-container'>
			{!sortedProducts.length ? (
				<div className='no-products-found'>
					<h2 className='text_222_color'>{words.noMatchingBooks}</h2>
				</div>
			) : (
				sortedProducts.map((book) => {
					const {
						_id,
						title,
						//original_price,
						//discounted_price,
						is_stock,
						authors,
						//rating,
						//reviews,
						//trending,
						imageUrl: img,
					} = book;

					return (
						<Tilt
							key={book._id}
							tiltMaxAngleX={5}
							tiltMaxAngleY={5}
							glareEnable={false}
							transitionSpeed={2000}
							scale={1.02}
						>
							<div className='product-card' key={_id}>
								<Link to={paths.bookDetailsPath + _id}>
									<div className='product-card-image'>
										<Tilt
											transitionSpeed={4000}
											tiltMaxAngleX={15}
											tiltMaxAngleY={15}
											scale={1.05}
										>
											<img src={img} alt={title} />
										</Tilt>
									</div>
								</Link>

								<div className='product-card-details'>
									<h3>{title}</h3>
									{/* <p className='ratings'>
										{rating}
										<BsFillStarFill color='orange' /> (
										{reviews} reviews){' '}
									</p> */}
									{/* <div className='price-container'>
										<p className='original-price'>
											${original_price}
										</p>
										<p className='discount-price'>
											${discounted_price}
										</p>
									</div> */}

									<p>
										{authors}
										{/* {words.genre}:{' '}
										{getGenreName(genre).showName} */}
									</p>
									{/*<div className='info'>
										{!is_stock && (
											<p className='out-of-stock warning_bk'>
												{words.bookNotAvailable}
											</p>
										)}
										trending && (
											<p className='trending'>Trending</p>
										)
									</div>*/}
								</div>

								<div className='product-card-buttons'>
									{
										<button
											disabled={cartLoading || !is_stock}
											onClick={() =>
												!isProductInCart(book)
													? addToCartHandler(book)
													: navigate(paths.cartPath)
											}
											className={
												!is_stock
													? 'cart-btn warning_bk_not_available'
													: 'cart-btn main_btn_bk'
											}
										>
											{!is_stock
												? words.bookNotAvailable
												: !isProductInCart(book)
												? words.addToCart
												: words.goToCart}
										</button>
									}

									{/* <button
										disabled={cartLoading || !is_stock}
										onClick={() =>
											!isProductInCart(book)
												? addToCartHandler(book)
												: navigate(paths.cartPath)
										}
										className={
											is_stock
												? 'cart-btn main_btn_bk'
												: 'cart-btn disabled-btn'
										}
									>
										{!isProductInCart(book)
											? words.addToCart
											: words.goToCart}
									</button> */}
									{/* <button
										onClick={() => wishlistHandler(product)}
										className='wishlist-btn'
									>
										!isProductInWishlist(product) ? (
										<AiOutlineHeart size={30} />
										) : (
										<AiTwotoneHeart size={30} />)
									</button> */}
								</div>
							</div>
						</Tilt>
					);
				})
			)}
		</div>
	);
};
