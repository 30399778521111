import { useNavigate } from 'react-router-dom';
import { paths, words } from '../../../constant/constants';
import '../Cart.css';

const NoItemInCart = () => {
	const navigate = useNavigate();
	return (
		<div className='no-items-container'>
			<h2 className='page-heading' style={{ color: '#333' }}>
				{words.noItemInCart}
			</h2>
			<button
				className='explore-btn main_btn_bk'
				onClick={() => navigate(paths.booksListPath)}
			>
				{words.explore}
			</button>
		</div>
	);
};

export default NoItemInCart;
