import React from 'react';
import './Footer.css';
import { SiWhatsapp, SiGmail } from 'react-icons/si';
import { ImPhone } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { words } from '../../constant/constants';

const COPY_RIGHT_YEAR = new Date().getFullYear();
const PHONE_NUMBER = '4915771017181';
const MAIL = 'anasmh919@gmail.com';

export const Footer = () => {
	let PHONE_LINK = 'tel:' + PHONE_NUMBER;
	//const WHATSAPP_MSG = 'السلام عليكم، أريد طلب بعض الكتب';
	let WHATSAPP_LINK = 'https://api.whatsapp.com/send?phone=' + PHONE_NUMBER;
	//+'&text=' +WHATSAPP_MSG
	let MAIL_LINK = 'mailto:' + MAIL;

	return (
		<div className='footer'>
			<div className='social-links'>
				<Link to={MAIL_LINK} target='_blank'>
					<SiGmail />
				</Link>
				<Link to={WHATSAPP_LINK} target='_blank'>
					<SiWhatsapp />
				</Link>
				<Link to={PHONE_LINK}>
					<ImPhone />
				</Link>
			</div>
			<small>
				{words.appName} &copy; {COPY_RIGHT_YEAR}
			</small>
		</div>
	);
};
