import React from 'react';

import './GenreTitle.css';
//import { words } from '../../../../constant/constants';

export const GenreTitle = ({ title }) => {
	return (
		<>
			<div className='hero-heading-container'>
				<h1 className='hero-title main_text_color'>{title}</h1>
				{/* <h2 className='hero-subtitle'>
					Discover the Heroic Sneaker Line that Transcends Boundaries
				</h2> */}
			</div>
		</>
	);
};
