import './ProductImage.css';
import Tilt from 'react-parallax-tilt';

import React from 'react';

export const ProductImage = ({ selectedBook }) => {
	return (
		<Tilt
			tiltEnable={false}
			scale={1.05}
			transitionSpeed={1000}
			className='product-details-image'
		>
			<img src={selectedBook?.imageUrl} alt={selectedBook.title} />
		</Tilt>
	);
};
