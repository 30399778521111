export const dateFormat = (dt) =>
	dt.toLocaleDateString('en-GB', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	});
// Format the time as hh24:mi:ss
export const timeFormat = (dt) =>
	dt.toLocaleTimeString('en-GB', {
		hour12: false,
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	});
