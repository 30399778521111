import React from 'react';
import './BookList.css';
import Tilt from 'react-parallax-tilt';
import { Link } from 'react-router-dom';

import { paths } from '../../../../constant/constants';
import Carousel from '../../../../components/Carousel/Carousel';

export const BookList = ({ books }) => {
	const list = books.map((book) => {
		return (
			<Tilt key={book._id} transitionSpeed={1000} scale={1.07}>
				<Link to={paths.bookDetailsPath + book._id}>
					<div className='video-card'>
						<div className='video-card2'>
							<img
								className='photo-card'
								src={book.imageUrl}
								alt={book.title}
							/>
							{/* <h4>{book.title}</h4>
								<span className='notch'></span> */}
						</div>
					</div>
				</Link>
			</Tilt>
		);
	});

	return (
		<>
			<div className='video-container'>
				<Carousel>{list}</Carousel>
			</div>
		</>
	);
};
